import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import { Intro, Studio, Help } from "./pages"
import { Toast } from "./components/form"

import "./global.sass"

export default class App extends React.Component {
  render() {
    return (<>
      <div className="text-center card p-3">
        <p><strong>Edit Subtitles with Ease – For Free!</strong><br />Need to add or edit subtitles for your videos? Try our <strong>Free Online Subtitle Editor</strong> – an easy-to-use, powerful tool to create and customize subtitles in no time!<br /><strong>Click below to start editing your subtitles now!</strong></p>
        <p><a href="https://subeditpro.com/" rel="noopener"><strong>Start Editing Now!</strong></a></p>
      </div>
      {/* <Studio/>
      <Toast /> */}
    </>)
  }
}